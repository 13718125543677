@import "https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.0.0-alpha.5/css/bootstrap.css";

body {
  background-color: #E8E8E8;
  @media screen and (max-width: 640px) {
    padding-top: 20px;
  }
}

#logo {
  background: url('http://clasi7icados.com/wp-content/themes/cachicha-v2/img/el-caribe/logo-full.png') no-repeat center left;
  height: 128px;
  @media screen and (max-width: 585px) {
    background-size: cover;
    width: 320px;
    height: 85px;
  }
}

#wrapper {
  background-color: #ffffff;
  /* box-shadow: 0 0 20px rgba(0, 0, 0, 0.4); */
  margin-top: 15px;
  padding: 15px;
  @media screen and (max-width: 640px) {
    padding: 20px 15px 10px;
  }
}

.color-bars {
  width: 100%;
  .color-bar {
    width: 16.66%;
    height: 6px;
    float: left;
    &.blue {
      background-color: #008da8;
    }
    &.lightblue {
      background-color: #3294d2;
    }
    &.green {
      background-color: #77b82a;
    }
    &.purple {
      background-color: #cf447b;
    }
    &.red {
      background-color: #ae321d;
    }
    &.orange {
      background-color: #ef8034;
    }
  }
}

.dashboard {
  .block {
    text-align: center;
    h2 {
      padding: 35px 0 15px;
    }
    .caption {
      padding: 10px;
      p {
        margin-bottom: 0;
        font-size: 1.1em;
      }
    }
    &.blue {
      color: white;
      background-color: #3294d2;
      .caption {
        background-color: #008da8;
      }
    }
    &.red {
      color: white;
      background-color: #F44336;
      .caption {
        background-color: #E53935;
      }
    }
    &.teal {
      color: white;
      background-color: #8BC34A;
      .caption {
        background-color: #7CB342;
      }
    }
  }
}

/**
 * Ads management
 */
.ad {
  .actions {
    background-color: #d9d9d9;
    padding: 15px;
    margin-bottom: -15px;
  }
}
.contact-box {

}

.navbar-fixed-top {
  /* position: absolute; */
}

thead.thead-inverse tr th {
  background: #008da8;
}
